import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

const Toast = () => {
	return (
		<ToastContainer
			hideProgressBar
			pauseOnFocusLoss
			pauseOnHover
			autoClose          = { 2000 }
			closeButton        = { false }
			closeOnClick       = { false }
			draggableDirection = { "y" }
			draggablePercent   = { 30 }
			limit              = { 1 }
			newestOnTop        = { false }
			position           = "bottom-center"
			theme              = "colored"
			transition         = { Slide }
		/>
	);

};

export default Toast;
